import { basisPointsField, selectField } from '../../models';
import { makeFormModel } from '../../helpers';
import { ProfitMarginsLimits } from 'ah-api-gateways';

export const profitMarginFM = (limits?: ProfitMarginsLimits) =>
  makeFormModel({
    name: 'profitMargin',
    title: 'Profit Markup',
    fieldType: 'form',
    inputMode: 'numeric',
    fields: [
      basisPointsField('partnerBasisPoints', 'Value', {
        minValue: limits?.minProfit || 0,
        maxValue: limits?.maxProfit || Infinity,
      }),
      selectField(
        'unit',
        'Unit',
        [
          {
            value: 'basisPoints',
            label: 'Basis points',
          },
          {
            value: 'percentage',
            label: 'Percentage',
          },
        ],
        {
          defaultValue: 'basisPoints',
          clearable: false,
          searchable: false,
          class: 'half-width',
        }
      ),
    ],
  });
